<script setup>
import { ref, onMounted, reactive, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from "primevue/usetoast";
import {FilterMatchMode} from 'primevue/api';

import UsuarioService from '../../../../service/administracao/UsuarioService';

const router = useRouter();
const toast = useToast();

const usuarioService = new UsuarioService();

const loading = ref(false);
const usuarios = ref([]);
const totalRecords = ref();
const deleteDialog = ref(false);
const usuario = ref();
const filters = reactive({});
const descricao = ref();
const tipo = ref({label : 'Todos', value : "T"});
const status = ref({label : 'Ativos', value : "A"});

const optionsTipo =  ref(
  [
    {label : 'Todos', value : "T"},
    {label : 'Cliente', value : "C"},
    {label : 'Sistema', value : "S"},
  ]
);
const optionsStatus =  ref(
  [
    {label : 'Ativos', value : "A"},
    {label : 'Excluídos', value : "D"},
  ]
);

const load = (options) => {
  loading.value = true;

  options = { ...options, filters: filters.value};
  if (descricao.value) {
    options.query = {};
    options.query.nomusu = descricao.value;
  }

  if (tipo.value) {
    if (!options) {
      options.query = {};
    }
    if (!options.query) {
      options.query = {};
    }

    options.query.tipo = tipo.value.value;
  }
  
  if (status.value) {
    if (!options) {
      options.query = {};
    }
    if (!options.query) {
      options.query = {};
    }

    options.query.status = status.value.value;
  }

  usuarioService.all(options)
  .then(
    (response) => {
      if (response) {
        totalRecords.value = response.totalRecords;
        usuarios.value = response.data;
      }
    }
  ).finally(()=>{
    loading.value = false;
  });
}

const onPage = (event) => load(event);

const onSort = (event) => load(event);

const novo = () => router.push({ path : '/administracao/fmtad02/novo' });

const edit = (id) => router.push({ path : '/administracao/fmtad02/' + id });

const confirmDeleteProduct = (data) => {
  usuario.value = data;
  deleteDialog.value = true;
}

const remove = () => {
  deleteDialog.value = false;
  loading.value = true;
  usuarioService.delete(usuario.value.CODUSU)
  .then(response => {
    toast.add({ severity:'success', summary: 'Sucesso', detail: 'Usuário removido com sucesso', life: 3000 });
    load();
  })
  .finally(() => {
    loading.value = false
  });
}

const applyFilter = () => {
  load();
}

onBeforeMount(() => {
  const initFilters = () => {
    filters.value = {
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
    }
  }
  initFilters();

  load();
})
</script>

<template>
  <div class="p-fluid">
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <Toast/>
          <h5>Usuários</h5>
          <DataTable 
            :value="usuarios" 
            :lazy="true" 
            :paginator="true" 
            :rows="10"
            :loading="loading"
            :totalRecords="totalRecords"
            @page="onPage($event)"
            @sort="onSort($event)"
            responsiveLayout="scroll"
            dataKey="CODUSU"
            :filters="filters"
            >
                <template #header>
                  <div class="formgrid">                
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center md:col-12">
                      <div class="col-2">
                        <Button label="Novo" icon="pi pi-plus" class="p-button-success" @click="novo" />
                      </div>
                      <div class="col-6">
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                          <i class="pi pi-search" />
                          <InputText v-model="descricao" placeholder="Procurar..." @input="applyFilter" />
                        </span>
                      </div>
                      <div class="col-2">
                        <div class="field col-12 md:col-12">
                          <label>Status</label>
                          <Dropdown v-model="status" :options="optionsStatus" optionLabel="label" placeholder="Selecione" @change="load">
                            <template #value="slotProps">
                              <div v-if="slotProps.value && slotProps.value.value">
                                <span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
                              </div>
                              <span v-else>
                                {{slotProps.placeholder}}
                              </span>
                            </template>
                          </Dropdown>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="field col-12 md:col-12">
                          <label>Tipo</label>
                          <Dropdown v-model="tipo" :options="optionsTipo" optionLabel="label" placeholder="Selecione" @change="load">
                            <template #value="slotProps">
                              <div v-if="slotProps.value && slotProps.value.value">
                                <span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
                              </div>
                              <span v-else>
                                {{slotProps.placeholder}}
                              </span>
                            </template>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #empty v-if="!loading">
                    Nenhum usuário encontrado
                </template>
                <template #loading>
                    Carregando usuários. Por favor, aguarde...
                </template>
                <template #footer v-if="!loading">
                Total de registros {{ totalRecords }}
                </template>
                <Column field="CODUSU" header="Código" :sortable="true" style="max-width:3rem">
                    <template #body="{data}">
                        {{data.CODUSU}}
                    </template>
                </Column>
                <Column field="NOMUSU" header="Descrição" :sortable="true" style="min-width:12rem">
                    <template #body="{data}">
                        {{data.NOMUSU}}
                    </template>
                </Column>
                <Column field="ATIUSU" header="Usuário Ativo" :sortable="true" style="min-width:12rem">
                    <template #body="slotProps">
                        {{((slotProps.data.deleted_at ? "Não" : "Sim"))}}
                    </template>
                </Column>
                <Column headerStyle="min-width:10rem; width:10rem;">
                  <template #body="props">
                      <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2" @click="edit(props.data.CODUSU)" />
                      <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteProduct(props.data)" />
                  </template>
                </Column>            
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmação de remoção" :modal="true">
      <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          Deseja realmente excluir este usuário 
          <strong style="padding-left: 5px;"> {{ usuario.NOMUSU }}</strong>?
      </div>
      <template #footer>
          <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
          <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="remove" />
      </template>
		</Dialog>
</template>